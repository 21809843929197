import React, { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import { styled } from '@mui/system';
import crown from '../../assets/Icons/crown 2.svg'
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const OverlayWrapper = styled(Box)({
  position: 'relative',
  display: 'inline-block',
  width:'100%'
});

const OverlayContent = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: '#333333b7', // Darker background
  color: 'white',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1,

});

const ChildWrapper = styled(Box)({
  pointerEvents: 'none', // Disable all pointer events for the child
});

const UpgradeOverlay = ({ children, small }) => {
const user = useSelector(state => state?.user?.user?.roles?.[0] ?? 'defaultRole');


  const [isSubscribed, setIsSubcribed] = useState(false)

  useEffect(()=>{
if(user === "Splash_Subcription_Owner"){
setIsSubcribed(true);
}else {
  setIsSubcribed(false)
}
  },[user])


  return (
    <>
{
  isSubscribed ? (
    <>
{children}
    </>
  ):(
    <>
 <OverlayWrapper>
      <ChildWrapper>{children}</ChildWrapper>
      <OverlayContent>
        <Link to="/dashboard/billings">
        <Button
          variant="contained"
          color="primary"
          sx={{fontSize: small ? '10px': '12px', }}

        >
          <img src={crown} width={ small ? 15 : 20} style={{marginRight:'10px'}}/>
  Upgrade to premium to view
        </Button></Link>
      </OverlayContent>
    </OverlayWrapper>
    </>
  )
}
      
    </>
 
  );
};

export default UpgradeOverlay;
