import React from "react";
import { useRoutes } from "react-router-dom";
import Waitlist from "../Website";
import Login from "../Website/Pages/Auth/Login";
import Register from "../Website/Pages/Auth/Register";
import Pricing from "../Website/Pages/Pricing";
import GetStarted from "../Website/Pages/GetStarted";
import {
  Account,
  BasicInformation,
  Billings,
  Experience,
  ExternalLinks,
  Gallery,
  Menu,
  Offerings,
  ReservationHours,
  Tables,
  RestaurantProfile,
  SharedLayout,
  GuestBook,
} from "../WebApp/Pages/Outlets";
import QuickSetup from "../WebApp/Components/RestaurantProfile/QuickSetup";
import FloorPlan from "../WebApp/Components/FloorPlan/FloorPlan";
import ProtectedRoute from "./ProtectedRoute";

import {
  Dashboard,
  NotFound,
  Reservations,
  SelectRestaurant,
} from "../WebApp/Pages/Screens";

import Room from "../WebApp/Components/Tables/Room";
import ReservationManagement from "../WebApp/Components/Reservations/ReservationManagement";
import CustomerSupport from "../Website/Component/Support/CustomerSupport";
import Faq from "../Website/Component/Support/Faq";
import Tutorial from "../Website/Component/Support/Tutorial";

import { motion } from "framer-motion";
import Ticket from "../WebApp/Components/Experience/Ticket";

import ResetPassword from "../Website/Pages/Auth/ResetPassword";

import AdvanceReport from "../WebApp/Components/AdvancedReport/AdvanceReport";
import RatingsReport from "../WebApp/Components/AdvancedReport/RatingsReport/RatingsReport";
import AdvancedReservation from "../WebApp/Components/AdvancedReport/Reservation/AdvancedReservation";
import CreatePassword from "../WebApp/Components/Account/CreatePassword";
import AdvancedExperience from "../WebApp/Components/AdvancedReport/Experience/AdvancedExperience";
import { PATHS } from "./route.path";

export function Routes() {
  const fadeInOutVariants = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  };

  const slideInVariants = {
    initial: { x: "-100vw" },
    animate: { x: 0 },
    exit: { x: "-100vw" },
  };
  const scaleVariants = {
    initial: { scale: 0 },
    animate: { scale: 1 },
    exit: { scale: 0 },
  };

  const rotateVariants = {
    initial: { rotate: -180 },
    animate: { rotate: 0 },
    exit: { rotate: 180 },
  };

  const colorVariants = {
    initial: { color: "#ff0000" },
    animate: { color: "#00ff00" },
    exit: { color: "#0000ff" },
  };
  let element = useRoutes([
    {
      path: PATHS.HOME,
      element: <Waitlist />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
    {
      path: PATHS.LOGIN,
      element: <Login />,
    },
    {
      path: PATHS.REGISTER,
      element: <Register />,
    },
    {
      path: PATHS.PRICING,
      element: <Pricing />,
    },
    {
      path: PATHS.GET_STARTED,
      element: <GetStarted />,
    },
    {
      path: PATHS.SETUP,
      element: <QuickSetup />,
    },
    {
      path: PATHS.RESET_PASSWORD,
      element: <ResetPassword />,
    },
    {
      path: PATHS.CREATE_PASSWORD,
      element: <CreatePassword />,
    },
    {
      path: PATHS.CUSTOMER_SUPPORT,
      element: (
        <motion.div
          variants={fadeInOutVariants}
          initial="initial"
          animate="animate"
          exit="exit"
        >
          <CustomerSupport />
        </motion.div>
      ),
    },
    {
      path: PATHS.FAQ,
      element: <Faq />,
    },

    {
      path: PATHS.GUEST_PLAN,
      element: <Reservations />,
    },

    {
      path: PATHS.TUTORIALS,
      element: <Tutorial />,
    },

    {
      path: PATHS.SELECT_RESTAURANT,
      element: (
        <ProtectedRoute>
          <SelectRestaurant />
        </ProtectedRoute>
      ),
    },

    {
      path: PATHS.DASHBOARD.DASHBOARD,
      element: (
        <ProtectedRoute>
          <motion.div
            variants={slideInVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{ duration: 1, ease: [0.22, 1, 0.36, 1] }}
          >
            <Dashboard />
          </motion.div>
        </ProtectedRoute>
      ),
    },
    {
      element: <SharedLayout />,
      children: [
        {
          path: PATHS.DASHBOARD.RESTAURANT_PROFILE,
          element: <RestaurantProfile />,
        },
        {
          path: PATHS.DASHBOARD.ADVANCE_REPORT.INDEX,
          element: (
            <motion.div
              variants={slideInVariants}
              initial="initial"
              animate="animate"
              exit="exit"
              transition={{ duration: 1, ease: [0.22, 1, 0.36, 1] }}
            >
              <AdvanceReport />
            </motion.div>
          ),
        },
        {
          path: PATHS.DASHBOARD.ADVANCE_REPORT.RATINGS,
          element: <RatingsReport />,
        },
        {
          path: PATHS.DASHBOARD.ADVANCE_REPORT.RESERVATION,
          element: <AdvancedReservation />,
        },
        {
          path: PATHS.DASHBOARD.ADVANCE_REPORT.EXPERIENCE,
          element: <AdvancedExperience />,
        },
        {
          path: PATHS.DASHBOARD.BASIC_INFORMATION,
          element: <BasicInformation />,
        },
        {
          path: PATHS.DASHBOARD.OFFERINGS,
          element: <Offerings />,
        },
        {
          path: PATHS.DASHBOARD.EXPERIENCE,
          element: <Experience />,
        },
        {
          path: PATHS.DASHBOARD.GALLERY,
          element: <Gallery />,
        },
        {
          path: PATHS.DASHBOARD.GUESTBOOK,
          element: <GuestBook />,
        },

        {
          path: PATHS.DASHBOARD.EXTERNAL_LINKS,
          element: <ExternalLinks />,
        },
        // {
        //   path: "/dashboard/guest-book-main",
        //   element: <GuestBookMain />,
        // },
        {
          path: PATHS.DASHBOARD.RESERVATION_MANAGEMENT,
          element: <ReservationManagement />,
        },

        {
          path: PATHS.DASHBOARD.MENU,
          element: <Menu />,
        },
        {
          path: PATHS.DASHBOARD.TABLES,
          element: <Tables />,
        },
        {
          path: PATHS.DASHBOARD.ROOM,
          element: <Room />,
        },

        {
          path: PATHS.DASHBOARD.FLOOR_PLAN,
          element: <FloorPlan />,
        },
        {
          path: PATHS.DASHBOARD.RESERVATION_HOURS,
          element: <ReservationHours />,
        },
        {
          path: PATHS.DASHBOARD.BILLINGS,
          element: <Billings />,
        },
        {
          path: PATHS.DASHBOARD.TICKET,
          element: <Ticket />,
        },
        {
          path: PATHS.DASHBOARD.ACCOUNT,
          element: <Account />,
        },
      ],
    },
  ]);
  return element;
}
