import {
  Avatar,
  Box,
  Button,
  List,
  ListItemButton,
  Skeleton,
  Typography,
  TextField,
  Grid,
  Paper,
  InputAdornment,
  CssBaseline,
} from "@mui/material";
import React, { useEffect, useState, useRef, useContext } from "react";
import logo from "../../../assets/Logo/logo2.svg";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { logoutUser } from "../../../store/userSlice";
import { getRestuarents } from "../../../store/restaurantSlice";
import { motion } from "framer-motion";
import { FiSearch } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import { Helmet } from "react-helmet-async";
import SEO from "../../../Utility/SEO";
import { ThemeContext, ThemeContextProvider } from "../../../theme/ThemeContextProvider";

const SelectRestaurant = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation()

  const {isDarkTheme} = useContext(ThemeContext)
  

  const restaurantLists = useSelector((state) => state.restaurants.data.result);
  const [value, setValue] = useState("");

  const [restaurantList, setRestaurantList] = useState(null);
  useEffect(() => {
    setRestaurantList(restaurantLists);
  }, [restaurantLists]);

    const locationState = location.state;

  useEffect(() => {
    dispatch(getRestuarents());
       setRestaurantList(restaurantLists);
  }, []);

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();

    // Filter items based on the search term
    const filteredItems = restaurantLists.filter((item) =>
      item.Name.toLowerCase().includes(searchTerm)
    );

    setRestaurantList(filteredItems);
    setValue(searchTerm);
  };

  const [selected, setSelected] = useState("");
  const handleSelect = (value, name, logo) => {
    setSelected(value);
    localStorage.setItem("restaurantID", value);
    localStorage.setItem("restaurantName", name);
    localStorage.setItem("restaurantLogo", logo);
  };

  const scaleVariants = {
    initial: { scale: 0 },
    animate: { scale: 1 },
    exit: { scale: 0 },
  };

  const handleLogout = () => {
    dispatch(logoutUser());
    navigate("/login");
  };

  const handleGoBack = () =>{
    window.history.back(); 
  }

  const handleContinue = () =>{
              if (locationState && locationState.redirectTo) {
      navigate(`${locationState?.redirectTo}`);
    } else {
  
      navigate('/dashboard');
    }
  }

  return (
    <>
      <SEO
        title="Reisty | Select Restaurant"
        description="Enhance your restaurant management with Reisty’s intuitive dashboard and real-time insights."
        name="Reisty"
        type="article"
        canonicalUrl="https://restaurant.reisty.com/dashboard/select"
      />
  
      <motion.div
        variants={scaleVariants}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 1, ease: [0.22, 1, 0.36, 1] }}
      >
        <ThemeContextProvider>
          <CssBaseline/>
        <Box sx={{ bgcolor: "background.default", height: "100vh" }}>
          <Box
            sx={{
              // height: "90vh",
              bgcolor: "background.default",
              display: "grid",
              placeItems: "center",
              overflow: "scroll",
              height: "100vh",
            }}
          >
            <Box
              align="center"
              sx={{
                width: { lg: "70%", md: "70%", sm: "90%", xs: "95%" },
                margin: "0 auto",
                display: "flex",
                flexDirection: "column",
                alignContent: "center",

                pt: 3,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <img src={logo} width={120} alt="logo" title="reisty" />
                <Box
                  sx={{ display: "flex", alignItems: "center", columnGap: 3 }}
                >
                  <Typography
                    sx={{
                      color: isDarkTheme ? "#ccc" : "#333",
                      fontWeight: 300,
                      fontSize: "12px",
                      cursor: "pointer",
                      textDecoration: "underline",
                      transition:'0.2s all linear',
                      "&:hover":{
                        fontSize:'14px',
                        color:'primary.main'
                      }
                    }}
                    onClick={handleGoBack}
                  >
                    Go Back{" "}
                  </Typography>
                  <span style={{    color: isDarkTheme ? "#fff" : "#333",}}>|</span>{" "}
                  <Typography
                    sx={{
                        color: isDarkTheme ? "#ccc" : "#333",
                      fontWeight: 300,
                      fontSize: "12px",
                      cursor: "pointer",
                      textDecoration: "underline",
                         transition:'0.2s all linear',
                      "&:hover":{
                        fontSize:'14px',
                        color:'primary.main'
                      }
                    }}
                    onClick={handleLogout}
                  >
                    Logout
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ mt: { lg: 17, md: 17, sm: 10, xs: 8 } }}>
                <Typography variant="h1" sx={{fontFamily:'optima'}}>Select Restaurant</Typography>
                <Typography
                  sx={{
                    
                    fontSize: {
                      lg: "18px",
                      md: "18px",
                      sm: "14px",
                      xs: "14px",
                    },
                    textTransform: "capitalize",
                    fontWeight: 700,
                  }}
                >
                  Kindly choose a restaurant from the provided list
                </Typography>
                <Typography
                  sx={{
                
                    fontSize: {
                      lg: "12px",
                      md: "12px",
                      sm: "12px",
                      xs: "10px",
                    },
                    textTransform: "capitalize",
                    fontWeight: 300,
                    lineHeight: "20px",
                  }}
                >
                  Your choice will help us personalize your experience and
                  ensure that you receive information tailored to your preferred
                  dining establishment
                </Typography>

                <Box sx={{ mt: 6, mb: 15 }}>
                  <Box sx={{ mb: 5 }}>
                    <TextField
                      placeholder="Search Restaurants"
                      size="small"
                      sx={{
                        width: "200px",
                        transition: "0.2s all linear",
                        "&:hover": {
                          width: { lg: "400px", md: "400px", xs: "300px" },
                        },
                      }}
                      // fullWidth
                      value={value}
                      onChange={handleSearch}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <FiSearch style={{     color: isDarkTheme ? "#ccc" : "#333", }} />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment
                            onClick={() => setValue("")}
                            position="end"
                          >
                            <IoClose style={{     color: isDarkTheme ? "#ccc" : "#333", }} />
                          </InputAdornment>
                        ),
                        style: {
                          fontFamily: "outfit",
                          fontSize: "13px",
                          borderRadius: "10px",
                          // width: "200px",
                          transition: "0.2s all linear",

                          // Replace with your desired font family
                        },
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      columnGap: 3,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {!restaurantList && (
                      <>
                        <Grid container columnSpacing={2}>
                          <Grid item lg={3} md={3} sm={2} xs={4}>
                            <Skeleton
                            animation="wave"
                              sx={{ bgcolor:isDarkTheme ?  "#ffffff1a" : "242424" }}
                              width={100}
                              height={100}
                              variant="circular"
                            />
                          </Grid>
                          <Grid item lg={3} md={3} xs={4}>
                            <Skeleton
                              sx={{ bgcolor: isDarkTheme ?  "#ffffff1a" : "242424"  }}
                              width={100}
                              height={100}
                                     animation="wave"
                              variant="circular"
                            />
                          </Grid>
                          <Grid item lg={3} md={3} xs={4}>
                            <Skeleton
                              sx={{ bgcolor: isDarkTheme ?  "#ffffff1a" : "242424"  }}
                              width={100}
                              height={100}
                                     animation="wave"
                              variant="circular"
                            />
                          </Grid>
                          <Grid item lg={3} md={3} xs={4}>
                            <Skeleton
                              sx={{ bgcolor: isDarkTheme ?  "#ffffff1a" : "242424"  }}
                              width={100}
                              height={100}
                                     animation="wave"
                              variant="circular"
                            />
                          </Grid>
                        </Grid>
                      </>
                    )}
                    <Grid container spacing={2} >
                      {restaurantList &&
                        restaurantList.map((item, index) => (
                          <Grid item lg={2} md={2} sm={2} xs={4} key={index}>
                            <Box
                              onClick={() =>
                                handleSelect(item.Id, item.Name, item?.Logo)
                              }
                              sx={{ cursor: "pointer" }}
                            >
                              <Avatar
                                sx={{
                                  width: "100px",
                                  height: "100px",
                                  border:
                                    selected === item.Id
                                      ? "5px solid "
                                      : "3px solid #BC172F",
                                      borderColor: isDarkTheme ? "#bc172f":'text.primary',
                                  transition: "0.2s all linear",
                                  mt: selected === item.Id ? -2 : 0,
                                }}
                                src={item.Logo}
                              />
                              <Typography
                                sx={{
                                  color: "#fff",
                                  mt: 2,
                                  fontWeight: 500,
                                  fontSize: {
                                    lg: "12px",
                                    md: "12px",
                                    sm: "12px",
                                    xs: "10px",
                                  },
                                  textTransform: "uppercase",
                                }}
                              >
                                {item.Name}
                              </Typography>
                            </Box>
                          </Grid>
                        ))}
                      <Grid item lg={2} sm={3} md={3} xs={4}>
                        <Box>
                          <Link to="/quick-setup">
                            <Button
                              sx={{
                                width: "100px",
                                height: "100px",
                                borderRadius: "50%",
                                border: "2px solid #BC172F",
                              }}
                              variant="outlined"
                            >
                              <AddIcon sx={{ fontSize: "46px" }} />
                            </Button>
                          </Link>
                          <Typography
                            sx={{
                              color: "#fff",
                              mt: 2,
                              fontWeight: 500,
                              fontSize: {
                                lg: "12px",
                                md: "12px",
                                sm: "12px",
                                xs: "10px",
                              },
                            }}
                          >
                            Add More
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>

                <Paper
                  sx={{
                    position: "fixed",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    width: { lg: "30%", md: "40%", sm: "50%", xs: "100%" },
                    margin: "0 auto",
                    height: "100px",
                    display: "grid",
                    placeItems: "center",
                    borderRadius: 0,
                    background: "#333",
                  }}
                >
          
                    <Button
                    onClick={handleContinue}
                      endIcon={<KeyboardDoubleArrowRightIcon />}
                      disabled={!selected}
                      sx={{
                        width: "300px",
                        height: "63px",
                        background: "#000",
                        color: "#fff",
                        textTransform: "initial",
                        fontWeight: 700,
                        "&:hover": { backgroundColor: "#151515b7" },
                        "&:disabled": {
                          color: "#fff",
                          bgcolor: "#5b5b5b",
                          cursor: "not-allowed",
                        },
                      }}
                    >
                      Continue
                    </Button>
                  
                </Paper>
              </Box>
            </Box>
          </Box>
        </Box>
        </ThemeContextProvider>
      </motion.div>
    </>
  );
};

export default SelectRestaurant;
