import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import Switch from "@mui/material/Switch";
import TeamMembers from "../../Components/Account/TeamMembers";
import { addTeamMember, getPermissions } from "../../../api";
import FormControlLabel from "@mui/material/FormControlLabel";
import MembersInfo from "../../Components/Account/MembersInfo";
import { ThemeContext } from "../../../theme/ThemeContextProvider";

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&::before, &::after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&::before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&::after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

const Account = () => {
  const user = JSON.parse(window.localStorage.getItem("userInfo"));
  const { enqueueSnackbar } = useSnackbar();

  const { toggleTheme } = useContext(ThemeContext);

  const [isLoading, settIsLoading] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const handleAlert = (variant, message) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant });
  };

  const [editDetails, setEditDetails] = useState(null);

  const handleEdit = (newData) => {
    setIsEdit(true);
    setEditDetails(newData);
  };
  const [permission, setPermission] = useState(null);
  const [permissionLoading, setPermissionLoading] = useState(false);

  const handleGetPermissions = async () => {
    setPermissionLoading(true);
    await getPermissions()
      .then((res) => {
        setPermissionLoading(false);
        setPermission(res?.data);
      })
      .catch((err) => {
        setPermissionLoading(false);
      });
  };

  useEffect(() => {
    handleGetPermissions();
  }, []);

  return (
    <>
      {isEdit ? (
        <>
          <MembersInfo
            handleBack={() => setIsEdit(false)}
            editDetails={editDetails}
          />
        </>
      ) : (
        <>
          <Box
            sx={{
              bgcolor: "background.default",
              borderBottom: "0.2px solid #ccccccb7",
              py: 1,
              px: 4,
              borderLeft: "0px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height: "80px",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontFamily: "optima",
                  fontSize: { lg: "24px", md: "24px", sm: "12px", xs: "12px" },
                }}
              >
                Account Information
              </Typography>
              <Typography sx={{ fontSize: { sm: "12px", xs: "10px" } }}>
                Manage your personal details, team members, and login
                credentials.
              </Typography>
            </Box>
            <Link to="/quick-setup">
              <Button variant="contained">Quick Setup</Button>
            </Link>
          </Box>

          <Box sx={{ px: 4, mt: 4 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <Typography sx={{ fontWeight: 500 }}>
                  Account controls
                </Typography>
                <Typography variant="captioin" sx={{ fontSize: "12px" }}>
                  Our map feature is linked to your restaurant name and address.
                </Typography>
              </Box>
              <Button
                variant="outlined"
                sx={{
                  bgcolor: "#1a1a1a",
                  border: "1px solid #dadada",
                  color: "#fff",
                }}
              >
                Edit
              </Button>
            </Box>
            <Box
              sx={{
                bgcolor: "background.card",
                borderRadius: "8px",
                mt: 3,
                px: 3,
                py: 3,
                boxShadow:
                  "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  columnGap: 4,
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <Typography
                  sx={{ fontWeight: 500, width: "20%", fontSize: "12px" }}
                >
                  Restaurant Admin:
                </Typography>
                <Typography sx={{ fontWeight: 500, fontSize: "12px" }}>{`${
                  user?.first_name || "--"
                } ${user?.last_name || "--"}`}</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  columnGap: 4,
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <Typography
                  sx={{ fontWeight: 500, width: "20%", fontSize: "12px" }}
                >
                  Email Address:
                </Typography>
                <Typography sx={{ fontWeight: 500, fontSize: "12px" }}>{`${
                  user?.email || "--"
                }`}</Typography>
              </Box>
              <Box sx={{ display: "flex", columnGap: 4, alignItems: "center" }}>
                <Typography
                  sx={{ fontWeight: 500, width: "20%", fontSize: "12px" }}
                >
                  Subscription Plan:
                </Typography>
                <Box
                  sx={{ display: "flex", columnGap: 4, alignItems: "center" }}
                >
                  <Typography sx={{ fontWeight: 500, fontSize: "12px" }}>
                    {user?.roles[0] || "Free"}
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{ borderRadius: "5px", fontSize: "10px" }}
                  >
                    Change Plan
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mt: 6,
              }}
            >
              <Box>
                <Typography sx={{ fontWeight: 500 }}>Security</Typography>
                <Typography variant="caption" sx={{ fontSize: "12px" }}>
                  Crafting a distinctive description of your restaurant aids
                  potential diners in discovering your establishment and what
                  sets it apart.
                </Typography>
              </Box>
              <Button
                variant="outlined"
                sx={{
                  bgcolor: "#1a1a1a",
                  border: "1px solid #dadada",
                  color: "#fff",
                }}
              >
                Change
              </Button>
            </Box>
            <Box
              sx={{
                bgcolor: "background.card",
                borderRadius: "5px",
                mt: 2,
                px: 3,
                py: 3,
                boxShadow:
                  "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  columnGap: 4,
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <Typography sx={{ fontWeight: 500 }}>
                  Change Password
                </Typography>
                {/* <Typography  sx={{color:'#fff', fontWeight:500}}>Michael Boston</Typography> */}
              </Box>
            </Box>
            {/* <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 6,
          }}
        >
          <Box>
            <Typography sx={{ fontWeight: 500 }}>
         Appearance
            </Typography>
            <Typography variant="caption" sx={{ fontSize: "12px", }}>
        Crafting a distinctive description of your restaurant aids potential diners in discovering your establishment and what sets it apart.
            </Typography>
          </Box>
    
        </Box>
        <Box sx={{ bgcolor: "background.card", borderRadius: "5px", mt: 2, px: 3, py: 3 , boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"}}>
          <Box
            sx={{ display: "flex", columnGap: 4, alignItems: "center", mb: 2 }}
          >
            <Typography sx={{  fontWeight: 500 }}>
            Light Mode
            </Typography>
 <FormControlLabel
        control={<Android12Switch defaultChecked />}
        label="Dark Mode"
        onChange={toggleTheme}
      />
          </Box>
        </Box> */}
            <TeamMembers handleEdit={handleEdit} />
          </Box>
        </>
      )}
    </>
  );
};

export default Account;
