import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Box,
  Typography,
  Modal,
  TextField,
  IconButton,
  InputLabel,
  Divider,
  LinearProgress,
} from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import { IoMailOutline } from "react-icons/io5";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { CloseOutlined } from "@mui/icons-material";
import { IoImageOutline } from "react-icons/io5";
import { sendEmail } from "../../../../api";
import Loader from "../../../Common/Loader";
import { useSnackbar } from "notistack";
import { SiMinutemailer } from "react-icons/si";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60vw",
  bgcolor: "#1a1a1a",

  boxShadow: 24,
  pt: 3,
  borderRadius: 2,
  boxSizing: "border-box",
};

const CssTextField = styled(TextField)({
  height: "35px",
  minWidth: "75%",
  color: "#fff",
  fontWeight: 500,
  "& label.Mui-focused": {
    color: "#fff",
  },
  "& .MuiInput-underline:after": {
    border: "none",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      border: "none",
      color: "#fff",
      fontWeight: 500,
    },
    "&.Mui-focused fieldset": {
      border: "none",
      color: "#fff",
      fontWeight: 500,
    },
  },
});
const SendEmail = ({ selectedGuest }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { enqueueSnackbar } = useSnackbar();
  const handleAlert = (variant, message) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant });
  };

  const defaultTemplate =
    '<p><strong>Hi </strong><strong style="color: rgb(0, 120, 215);">@Model.Name</strong><strong>,</strong></p><p><br></p><p>We are delighted to invite you to a special "Dine for Free" event, an exclusive evening of exquisite cuisine and delightful company. Join us for an unforgettable dining experience, where you can savor a variety of gourmet dishes prepared by our renowned chefs, all on the house! This event is our way of expressing gratitude to our valued guests like you.</p><p><br></p><p>Event Details:</p><ul><li>Date: [Event Date]</li><li>Time: [Event Time]</li><li>Location: [Event Venue], [Venue Address]</li></ul><p><br></p><p>Please RSVP by [RSVP Date] to confirm your attendance. You can RSVP by replying to this email or by calling us at [Phone Number].</p><p><br></p><p>We look forward to welcoming you and sharing a wonderful evening together.</p><p><br></p><p>Thanks,</p><p><strong>Restaurant Name</strong></p>';

  const [template, setTemplate] = useState(defaultTemplate);

  const handleChange = (value) => {
    setTemplate(value);
  };

  const [subject, setSubject] = useState("");

  const fileInputRef = useRef(null);
  const [selectedFileURL, setSelectedFileURL] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleButtonClick = () => {
    fileInputRef.current.click(); // Trigger file input click
  };

  const [isSuccessful, setIsSuccessful] = useState(false);

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    // setUploadFile(file);

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const dataURL = e.target.result;
        setSelectedFileURL(dataURL);
      };
      reader.readAsDataURL(file);
    }
  };

  const transformedData = selectedGuest.map(
    (item) => `${item.email}_${item.name}`
  );

  const handleSendEmail = async () => {
    setIsLoading(true);
    setIsSuccessful(false);
    await sendEmail(template, subject, selectedFile, transformedData)
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status) {
          setIsSuccessful(true);
          handleAlert("success", `Email sent successfully`);
        } else {
          handleAlert("error", `${res?.data?.error_message}`);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        handleAlert("error", `${err?.message}`);
      });
  };

  useEffect(() => {
    if (isSuccessful) {
      setTimeout(() => {
        setIsSuccessful(false);
      }, 3000);
    }
  }, [isSuccessful]);

  return (
    <>
      <Button
        onClick={handleOpen}
        startIcon={<IoMailOutline />}
        sx={{ bgcolor: "#fff", px: 3 }}
        disabled={selectedGuest?.length === 0}
      >
        Send mail
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {isLoading && <Loader />}
          <Box
            sx={{
              px: 4,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              bgcolor: isSuccessful && "#10893e",
            }}
          >
            {isLoading ? (
              <>
                <Box sx={{ width: "100%" }}>
                  <Typography
                    sx={{ mb: 1, textAlign: "center", color: "#ccc" }}
                  >
                    Sending...
                  </Typography>
                  <LinearProgress />
                </Box>
              </>
            ) : (
              <>
                {isSuccessful ? (
                  <>
                    <Typography
                      sx={{
                        color: "#fff",
                        textAlign: "center",
                        my: 1,
                        fontSize: "12px",
                      }}
                    >
                      Email Sent Successfully{" "}
                      <SiMinutemailer style={{ fontSize: "20px" }} />
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography
                      sx={{
                        color: "#fff",
                        fontWeight: 500,
                      }}
                    >
                      {selectedGuest?.length} Guest selected
                    </Typography>

                    <IconButton
                      onClick={handleClose}
                      sx={{ border: "0.5px solid #ccc", mb: 1 }}
                    >
                      <CloseOutlined sx={{ color: "#fff" }} />
                    </IconButton>
                  </>
                )}
              </>
            )}
          </Box>
          <Box sx={{ px: 0, py: 2, bgcolor: "#565A60" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: 2,
                height: "40px",
                borderBottom: "0.43px solid #BCBCBC",
                px: 4,
              }}
            >
              <Typography
                color={"primary"}
                sx={{ fontWeight: 500, fontSize: "12px" }}
              >
                From :
              </Typography>
              <Typography
                sx={{ fontWeight: 500, fontSize: "12px", color: "#fff" }}
              >
                support@reisty.com
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: 2,
                height: "40px",
                borderBottom: "0.43px solid #BCBCBC",
                px: 4,
              }}
            >
              <Typography
                color={"primary"}
                sx={{ fontWeight: 500, fontSize: "12px", whiteSpace: "nowrap" }}
              >
                To :
              </Typography>
              <InputLabel
                sx={{ fontWeight: 500, fontSize: "12px", color: "#fff" }}
              >
                {selectedGuest &&
                  selectedGuest?.map((guest) => guest?.email).join(", ")}
              </InputLabel>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: 2,
                height: "40px",
                borderBottom: "0.43px solid #BCBCBC",
                px: 4,
              }}
            >
              <Typography
                color={"primary"}
                sx={{ fontWeight: 500, fontSize: "12px" }}
              >
                Subject :
              </Typography>

              <CssTextField
                sx={{ width: "auto" }}
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                placeholder="Subject"
                InputProps={{
                  style: {
                    fontFamily: "outfit",
                    fontSize: "12px",
                    borderRadius: "10px",
                    color: "#fff",
                    fontWeight: 500,
                  },
                }}
                size="small"
                id="custom-css-outlined-input"
              />
            </Box>
            <Box sx={{ mt: 2, px: 4 }}>
              <ReactQuill
                className="editor"
                theme="snow"
                value={template}
                onChange={handleChange}
              />
            </Box>
            {selectedFile && (
              <Box sx={{ mt: 2, px: 4 }}>
                <Box
                  sx={{
                    background: `url('${selectedFileURL}')`,
                    height: "60px",
                    width: "80px",
                    backgroundSize: "cover",
                  }}
                />
                <Typography sx={{ color: "#ccc", fontSize: "10px" }}>
                  {selectedFile?.name}
                  <IconButton onClick={() => setSelectedFile(null)}>
                    <CloseOutlined
                      sx={{ fontSize: "14px", color: "#BC172F" }}
                    />
                  </IconButton>{" "}
                </Typography>
              </Box>
            )}
            <Divider sx={{ my: 2 }} />
            <Box
              sx={{
                mt: 2,
                px: 4,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box></Box>
              {/* <Button
                startIcon={<IoImageOutline />}
                sx={{ color: "#fff" }}
                onClick={handleButtonClick}
              >
                Add Image
              </Button> */}
              <input
                accept="image/*"
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileSelect}
              />
              <Button
                onClick={handleSendEmail}
                disabled={!subject || !template || !selectedGuest}
                variant="contained"
              >
                Send Now
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default SendEmail;
