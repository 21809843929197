import {
  Button,
  Box,
  Typography,
  ToggleButtonGroup,
  IconButton,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import CustomInput from "../CustomField/CustomInput";
import {
  addSpecialOccasion,
  createReservationHour,
  editOccasion,
} from "../../../api";
import Loader from "../../Common/Loader";
import { useSnackbar } from "notistack";
import { daysInWeek } from "date-fns";
import { styled } from "@mui/material/styles";
import CustomModal from "../../Common/CustomModal";
import CustomTextField from "../CustomField/CustomTextField";
import pen from "../../../assets/Icons/pen.svg";

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#BC172F",
    },
  },
});

const EditOccasion = ({ action, data }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isLoading, setIsLoading] = useState(false);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [name, setName] = useState("");
  const [date, setDate] = useState("");

  const handleAlert = (variant, message) => {
    enqueueSnackbar(message, { variant });
  };
  const [formats, setFormats] = React.useState(() => []);

  const handleFormat = (event, newFormats) => {
    setFormats(newFormats);
  };
  const [restaurantType, setRestaurantType] = useState("");

  const handleChangeSelect = (event) => {
    setRestaurantType(event.target.value);
  };

  const dayOfWeeks = formats.join(",");

  const handleCreateReservationHour = async () => {
    setIsLoading(true);
    await editOccasion(data?.Id, name, date, from, to)
      .then((res) => {
        setIsLoading(false);
        if (res.data.status) {
          handleAlert("success", "Special occasion  edited successfully");
          handleCloseModal();
          action();
        } else {
          handleAlert("error", `${res?.data.error_message}`);
        }
      })
      .catch((err) => {
        setIsLoading(false);

        handleAlert("error", `${err}`);
      });
  };

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const resWidth = {
    lg: 500,
    md: 500,
    sm: 400,
    xs: 350,
  };

  useEffect(() => {
    if (data) {
      setName(data?.EventName);
      setDate(data?.EventDate);
      setFrom(data?.EventTimeFrom);
      setTo(data?.EventTimeTo);
    }
  }, [data]);
  return (
    <>
      <IconButton onClick={handleOpenModal}>
        <img src={pen} alt="pen_icon" />
      </IconButton>

      <ThemeProvider theme={theme}>
        {/* <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        ></Modal> */}

        <CustomModal
          open={openModal}
          onClose={handleCloseModal}
          title="     Edit  Special Occasion"
          width={resWidth}
        >
          <Box>
            {isLoading && <Loader />}

            <Box sx={{ mt: 4, bgcolor: "", borderRadius: 2, p: 2 }}>
              <Box sx={{ mt: 2 }}>
                <CustomTextField
                  name="Name of event"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Box>
              <Box sx={{ mt: 3 }}>
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: 500,
                    mb: 1,
                  }}
                >
                  Date of Event:
                </Typography>
                <CustomTextField
                  type={"date"}
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />
              </Box>

              <Box
                sx={{
                  mt: 3,
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "13px",
                    fontWeight: 500,
                    fontFamily: "outfit",
                  }}
                >
                  Time of Event:
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    columnGap: 2,
                  }}
                >
                  <Box sx={{ width: "45%" }}>
                    <Typography
                      sx={{ fontSize: "12px", color: "#fff", mt: 2, mb: 1 }}
                    >
                      From:
                    </Typography>
                    <CustomInput
                      size="normal"
                      type="time"
                      value={from}
                      onChange={(e) => setFrom(e.target.value)}
                    />
                  </Box>
                  <Box sx={{ width: "45%" }}>
                    <Typography
                      sx={{ fontSize: "12px", color: "#fff", mt: 2, mb: 1 }}
                    >
                      To:
                    </Typography>
                    <CustomInput
                      size="normal"
                      type="time"
                      value={to}
                      onChange={(e) => setTo(e.target.value)}
                    />
                  </Box>
                </Box>

                <Box align="right" sx={{ mt: 4 }}>
                  <Button
                    disabled={!to || !from || !name || !date}
                    variant="contained"
                    fullWidth
                    sx={{
                      py: 2,
                      px: 4,
                      borderRadius: "10px",
                      textTransform: "initial",
                      fontFamily: "outfit",
                      fontWeight: 500,
                    }}
                    onClick={() => {
                      handleCreateReservationHour();
                    }}
                  >
                    Save Changes
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </CustomModal>
      </ThemeProvider>
    </>
  );
};

export default EditOccasion;
