import React, { useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Popover,
  Button,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import calenderTick from "../../assets/Icons/calendar-tick2.svg";
import calender from "../../assets/Icons/calendar.svg";
import messIcon from "../../assets/Icons/messages.svg";
import { format, parseISO } from "date-fns";
import book from "../../assets/Icons/book3.svg";
import NewWalkins from "../Components/Reservations/NewWalkins";
import NewReservation from "../Components/Reservations/NewReservation";
import Calender from "./Calender";

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#BC172F",
    },
  },
  typography: {
    fontFamily: "outfit",
    fontSize: "14px",
  },
});

const WalkinHeader = ({ updateDate, action }) => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const dateObject = new Date();

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };
  // Format the date if needed
  const toDate = dateObject.toISOString().split("T")[0];
  const [value, setValue] = React.useState(toDate);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const parsedDate = parseISO(value);
  const formattedDate = format(parsedDate, "EEEE, MMMM d");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleFilter = async () => {
    updateDate(value);
    handleClose();
  };
  return (
    <>
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            p: 2,
            bgcolor: "#000",
            borderBottom: "0.5px solid #fff",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: { lg: "row", md: "row", sm: "column", xs: "column" },
            rowGap: 3,
          }}
        >
          <Box
            sx={{
              p: 0,
              bgcolor: "#333",
              border: "0.5px solid #fff",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              borderRadius: "8px",
            }}
            onClick={handleClick}
          >
            <IconButton>
              <ChevronLeftIcon sx={{ color: "#fff" }} />
            </IconButton>
            <IconButton>
              <img src={calenderTick}  alt="calender_icon"/>
            </IconButton>
            <Typography
              sx={{ color: "#fff", fontSize: "11px", fontWeight: 500 }}
            >
              {formattedDate}
            </Typography>
    
            <IconButton>
              <ChevronRightIcon sx={{ color: "#fff" }} />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              columnGap: 2,
              alignItems: "center",
              width: {
                lg: "auto",
                md: "auto",
                sm: "100%",
                xs: "100%",
                justifyContent: "space-between",
              },
            }}
          >
  
            <NewWalkins action={action}/>
            <Button
              // disabled
              onClick={handleDrawerOpen}
              sx={{
                p: 0,
                pr: 2,
                bgcolor: "#333",
                border: "0.5px solid #fff",
                display: "flex",
                alignItems: "center",
                borderRadius: "8px",
              }}
            >
              <IconButton>
                <img src={book} width={20} alt="book_icon" />
              </IconButton>
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: "11px",
                  fontWeight: 500,
                  textTransform: "initial",
                }}
              >
                New Reservation
              </Typography>

              {/* <IconButton></IconButton> */}
            </Button>
            <NewReservation open={drawerOpen} onClose={handleDrawerClose}  action={action}/>
          </Box>
        </Box>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Calender
            future={false}
            past={false}
            onChildValue={(value) => setValue(value)}
          />
          <Box sx={{ p: 2 }} align="right">
            <Button onClick={handleFilter} variant="contained" sx={{ px: 4 }}>
              Filter
            </Button>
          </Box>
        </Popover>
      </ThemeProvider>
    </>
  );
};

export default WalkinHeader;
