import React, { useEffect, useState } from "react";
import {
  MenuItem,
  Typography,
  Modal,
  Box,
  IconButton,
  Grid,
  TextField,
  InputLabel,
  Select,
  Divider,
  Button,
  LinearProgress,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { CloseOutlined } from "@mui/icons-material";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import PostAddIcon from "@mui/icons-material/PostAdd";
import CustomInput from "../../CustomField/CustomInput";
import { addMoreTables, editTable } from "../../../../api";
import { useSnackbar } from "notistack";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 520,
  bgcolor: "#1a1a1a",
  // border: "2px solid #000",
  boxShadow: 24,
  borderRadius: 2,
  // p: 4,
};

const AddMoreTables = ({ id, action }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [name, setName] = useState("");
  const [settings, setSettings] = useState("");
  const [rows, setRows] = useState("");
  const [color, setColor] = useState("");
  const [shape, setShape] = useState("");
  const [min, setMin] = useState(null);
  const [max, setMax] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [duplicateCount, setDuplicateCount] = useState(0);

  const handleAlert = (variant, message) => {
    enqueueSnackbar(message, { variant });
  };

  const handleAddMore = async () => {
    setIsLoading(true);

    const payload = [
      {
        name,
        maxSize: max,
        minSize: min,
        position: rows,
        setting: settings,
        shape,
        type: "tableRec",
        point: {
          x: 0,
          y: 0,
        },
      },
    ];

    function duplicatePayload(payload, count) {
      const duplicatedPayload = [];
      for (let i = 0; i < count; i++) {
        duplicatedPayload.push(...payload);
      }
      return duplicatedPayload;
    }

    const addedDuplicateCount = parseFloat(duplicateCount) + 1;
    const result = duplicatePayload(payload, addedDuplicateCount);

    await addMoreTables(id, result)
      .then((res) => {
        setIsLoading(false);
        const { data } = res;
        if (data.status) {
          handleAlert("success", "Table created successfully");
          setOpen(false);
          action();
        } else {
          handleAlert("error", `${data?.result[0]}`);
        }
      })
      .catch((err) => {
        setIsLoading(false);

        handleAlert("error", err.message);
      });
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        variant="contained"
        sx={{
          // border: "0.5px solid #fff",
          color: "#fff",
          fontWeight: 400,
          px: 6,
          mr: 3,
          fontSize: "11px",
          borderRadius: "10px",
        }}
      >
        <PostAddIcon sx={{ mr: 1, fontSize: "15px" }} />
        Add More
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              p: 1.5,
              bgcolor: "#333",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "2px solid #fff",
              borderRadius: "8px 8px 0px 0px",
            }}
          >
            <Typography sx={{ color: "#fff", fontWeight: 500 }}>
              Add more table
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseOutlined sx={{ color: "#fff" }} />
            </IconButton>
          </Box>
          {isLoading && <LinearProgress />}

          <Box sx={{ p: 3 }}>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ color: "#fff", fontSize: "13px" }}>
                    Table Name
                  </Typography>
                  <TextField
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    sx={{ width: "60%" }}
                    InputProps={{
                      style: {
                        fontFamily: "outfit",
                        fontSize: "13px",
                        borderRadius: "10px",
                        border: "1px solid #fff",
                        color: "#fff",
                        offset: " 1px solid #fff",
                      },
                    }}
                  />
                </Box>
              </Grid>
              <Grid item lg={12} md={12}>
                <Box>
                  <InputLabel sx={{ color: "#fff", mt: 1, fontSize: "13px" }}>
                    Table Setting
                  </InputLabel>
                  <Select
                    value={settings}
                    onChange={(e) => setSettings(e.target.value)}
                    fullWidth
                    sx={{
                      mt: 1,
                      border: "1px solid #fff",
                      color: "#fff",
                      borderRadius: "10px",
                      fontSize: "12px",
                    }}
                    displayEmpty
                  >
                    <MenuItem
                      value="Formal"
                      sx={{ fontSize: "12px", color: "#fff" }}
                    >
                      Formal
                    </MenuItem>
                    <MenuItem
                      value="Casual"
                      sx={{ fontSize: "12px", color: "#fff" }}
                    >
                      Casual
                    </MenuItem>
                    <MenuItem
                      value="Breakfast"
                      sx={{ fontSize: "12px", color: "#fff" }}
                    >
                      Breakfast
                    </MenuItem>
                    <MenuItem
                      value="Buffet"
                      sx={{ fontSize: "12px", color: "#fff" }}
                    >
                      Buffet
                    </MenuItem>
                    <MenuItem
                      value="Five-Course"
                      sx={{ fontSize: "12px", color: "#fff" }}
                    >
                      Five-Course
                    </MenuItem>
                  </Select>
                </Box>
              </Grid>
              <Grid item lg={12} md={12}>
                <Box>
                  <InputLabel sx={{ color: "#fff", mt: 1, fontSize: "13px" }}>
                    No of seats
                  </InputLabel>
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6}>
                      <InputLabel
                        sx={{ color: "#fff", fontSize: "10px", my: 1 }}
                      >
                        Min
                      </InputLabel>
                      <CustomInput
                        type="number"
                        value={min}
                        name="0"
                        onChange={(e) => setMin(parseInt(e.target.value))}
                      />
                    </Grid>
                    <Grid item lg={6} md={6}>
                      <InputLabel
                        sx={{ color: "#fff", fontSize: "10px", my: 1 }}
                      >
                        Max
                      </InputLabel>
                      <CustomInput
                        type="number"
                        value={max}
                        name="0"
                        onChange={(e) => setMax(parseInt(e.target.value))}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item lg={6} md={6}>
                <Box>
                  <InputLabel sx={{ color: "#fff", fontSize: "13px" }}>
                    Table Position
                  </InputLabel>
                  <Select
                    value={rows}
                    onChange={(e) => setRows(e.target.value)}
                    fullWidth
                    sx={{
                      mt: 1.7,
                      border: "1px solid #fff",
                      color: "#fff",
                      borderRadius: "10px",
                      fontSize: "12px",
                    }}
                    displayEmpty
                  >
                    <MenuItem
                      value="Front row"
                      sx={{ fontSize: "12px", color: "#fff" }}
                    >
                      Front row
                    </MenuItem>
                    <MenuItem
                      value="Centre"
                      sx={{ fontSize: "12px", color: "#fff" }}
                    >
                      Centre
                    </MenuItem>
                    <MenuItem
                      value="Window side"
                      sx={{ fontSize: "12px", color: "#fff" }}
                    >
                      Window side
                    </MenuItem>
                    <MenuItem
                      value="Corner"
                      sx={{ fontSize: "12px", color: "#fff" }}
                    >
                      Corner
                    </MenuItem>
                    <MenuItem
                      value="Other"
                      sx={{ fontSize: "12px", color: "#fff" }}
                    >
                      Other
                    </MenuItem>
                  </Select>
                </Box>
              </Grid>

              <Grid item lg={6} md={6}>
                <Box>
                  <InputLabel sx={{ color: "#fff", mt: 1, fontSize: "13px" }}>
                    Table Shape
                  </InputLabel>
                  <Select
                    fullWidth
                    value={shape}
                    onChange={(e) => setShape(e.target.value)}
                    sx={{
                      mt: 1,
                      border: "1px solid #fff",
                      color: "#fff",
                      borderRadius: "10px",
                      fontSize: "12px",
                    }}
                    displayEmpty
                  >
                    <MenuItem
                      value="Square"
                      sx={{ fontSize: "12px", color: "#fff" }}
                    >
                      Square
                    </MenuItem>
                    <MenuItem
                      value="Rectangle"
                      sx={{ fontSize: "12px", color: "#fff" }}
                    >
                      Rectangle
                    </MenuItem>
                    <MenuItem
                      value="Circle"
                      sx={{ fontSize: "12px", color: "#fff" }}
                    >
                      Circle
                    </MenuItem>
                    <MenuItem
                      value="Oval"
                      sx={{ fontSize: "12px", color: "#fff" }}
                    >
                      Oval
                    </MenuItem>
                    <MenuItem
                      value="Custom"
                      sx={{ fontSize: "12px", color: "#fff" }}
                    >
                      Custom
                    </MenuItem>
                  </Select>
                </Box>
              </Grid>
            </Grid>
            <Box
              sx={{
                mt: 3,
                borderTop: "1px solid #fff",
                pt: 2,
                display: "flex",
                alignItems: "center",
                columnGap: 3,
              }}
            >
              <Typography sx={{ color: "#fff", fontSize: "12px" }}>
                Duplicate this table by:
              </Typography>
              <Box sx={{ width: "20%" }}>
                <CustomInput
                  type="number"
                  value={duplicateCount}
                  onChange={(e) => setDuplicateCount(e.target.value)}
                />
              </Box>
            </Box>
            <Box
              sx={{
                pt: 2,
                mt: 3,
                display: "flex",
                alignItems: "center",
                columnGap: 2,
              }}
            >
              <Button
                sx={{
                  bgcolor: "#fff",
                  color: "#333",
                  py: 2,
                  borderRadius: "10px",
                }}
                fullWidth
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                fullWidth
                onClick={handleAddMore}
                disabled={
                  isLoading ||
                  !name ||
                  !rows ||
                  !settings ||
                  !max ||
                  !min ||
                  !shape
                }
                sx={{
                  py: 2,
                  borderRadius: "10px",
                  "&.Mui-disabled": {
                    bgcolor: "#333",
                    color: "#ccc",
                    cursor: "not-allowed",
                  },
                }}
              >
                Add Table
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default AddMoreTables;
