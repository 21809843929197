import React, { useEffect, useState } from "react";
import TopNav from "../../Layout/TopNav";
import {
  Avatar,
  Box,
  Button,
  Grid,
  Typography,
  Popover,
  createTheme,
  ThemeProvider,
  MenuItem,
  CssBaseline,
} from "@mui/material";

import {
  getDashboadTables,
  getDashboardOverview,
  getDashboardOverviewAnalysis,
  getDashboardOverviewValue,
  getReservationNotes,
} from "../../../api";
import Loader from "../../Common/Loader";
import Calender from "../../Common/Calender";
import { format, parseISO } from "date-fns";
import { Helmet } from "react-helmet-async";
import GeneralOverview from "../../Components/AppWidget/GeneralOverview";
import SalesSummary from "../../Components/AppWidget/SalesSummary";
import ReservationDetails from "../../Components/AppWidget/ReservationDetails";
import Dropdown from "../../Common/Dropdown";

import exportIcon from "../../../assets/Icons/export.svg";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { IoIosArrowDown } from "react-icons/io";
import SEO from "../../../Utility/SEO";
import { useSelector } from "react-redux";
import { ThemeContextProvider } from "../../../theme/ThemeContextProvider";

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#BC172F",
    },
  },
  typography: {
    fontFamily: "outfit",
    button: {
      textTransform: "initial",
    },
  },
});

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Dashboard = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [tabValue, setTabValue] = React.useState(0);
  const [isOverview, setIsOverview] = useState(true);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const [data, setData] = useState(null);
  const dateObject = new Date();
  const toDate = dateObject.toISOString().split("T")[0];
  const [value, setValue] = React.useState(toDate);
  const [isLoading, setIsLoading] = useState(false);
  const user = JSON.parse(window.localStorage.getItem("userInfo"));
  const parsedDate = parseISO(value);
  const formattedDate1 = format(parsedDate, "EEEE, MMMM d");
  const date = new Date();
  const formatDate = () => {
    const options = { weekday: "long", month: "long", day: "numeric" };

    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const day = String(date.getDate()).padStart(2, "0");
  const formattedDate = formatDate();

  const handleGetDashboard = async () => {
    setIsLoading(true);
    await getDashboardOverview(formattedDate)
      .then((res) => {
        const { data } = res;
        setIsLoading(false);
        setData(data?.result[0]);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    handleGetDashboard();
    // handleGetNotes();
  }, []);

  const [days, setDays] = useState(1);

  const fetchCustomData = (key) => {
    setDays(key);
  };

  const [notes, setNotes] = useState(null);
  // const handleGetNotes = async () => {
  //   setIsLoading(true);
  //   await getReservationNotes(days)
  //     .then((res) => {
  //       setIsLoading(false);

  //     })
  //     .catch((err) => {
  //       setIsLoading(false);
  //     });
  // };

  // useEffect(() => {
  //   handleGetNotes();
  // }, [days]);

  const [dashboadValues, setDashboardValues] = useState(null);
  const [dashboardChart, setDashboardChart] = useState(null);

  const [dayInterval, setDayInterval] = useState(7);
  const [dropdownLabel, setDropdownLabel] = useState("Last_7_days");

  const handleGetDashboardValue = async () => {
    setIsLoading(true);

    handleClose();
    await getDashboardOverviewValue()
      .then((res) => {
        const { data } = res;
        setIsLoading(false);
        setDashboardValues(data?.result);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const [detailsData, setDetailsData] = useState(null);
  const handleDetailsScreen = (newData) => {
    setIsOverview(!isOverview);
    setDetailsData(newData);
  };

  const [dayIntervals, setDayIntervals] = useState("");

  const handleUpdateDayInterval = (value) => {
    setDayIntervals(value);
  };
  const handleUpdateDate = (value) => {
    setValue(value);
  };

  const [chartData, setChartData] = useState(null);

  const handleFilter = async () => {
    setIsLoading(true);

    handleClose();
    await getDashboardOverview(value)
      .then((res) => {
        const { data } = res;
        setIsLoading(false);
        setChartData(data?.result[0]);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleGetDashboardAnalysis = async () => {
    setIsLoading(true);

    await getDashboardOverviewAnalysis(dayInterval)
      .then((res) => {
        const { data } = res;
        setIsLoading(false);
        setDashboardChart(data?.result);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    handleFilter();
    handleTableData();
    handleGetDashboardAnalysis();
  }, [dayInterval]);

  const [tableData, setTableData] = useState(null);

  const handleTableData = async () => {
    setIsLoading(true);
    await getDashboadTables(dayInterval)
      .then((res) => {
        setIsLoading(false);

        setTableData(res?.data?.result);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    handleTableData();
  }, []);

  const [anchorEl2, setAnchorEl2] = React.useState(null);

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const open2 = Boolean(anchorEl2);
  const id2 = open2 ? "simple-popover" : undefined;

  const handleClickMenu = (value, label) => {
    handleClose2();
    setDayInterval(value);
    setDropdownLabel(label);
  };

  const userPermissions = useSelector(
    (state) => state?.user?.user?.Permissions
  );
  return (
    <>
      <SEO
        title="Reisty | Restaurant Dashboard "
        description="Enhance your restaurant management with Reisty’s intuitive dashboard and real-time insights."
        name="Reisty"
        type="article"
        canonicalUrl="https://restaurant.reisty.com/dashboard"
      />
      <ThemeContextProvider>
        {/* <CssBaseline/><CssBaseline/> */}
        <TopNav />
        {isLoading && <Loader />}

        <Box
          sx={{
            bgcolor: "background.default",
            height: "100vh",
            overflow: "scroll",
          }}
        >
          <Box
            sx={{
              margin: "0 auto",
              width: { xl: "80%", lg: "90%", md: "90%", sm: "90%", xs: "90%" },
              pt: 13,
              pb: 10,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  textTransform: "capitalize",
                  fontSize: "13px",
                  fontFamily: "optima",
                }}
              >
                Hello, <span style={{ fontWeight: 700 }}>{user.fullname}</span>{" "}
              </Typography>
              <Button
                endIcon={<img src={exportIcon} />}
                sx={{
                  bgcolor: "#1a1a1a",
                  color: "#fff",
                  fontSize: "12px",
                  fontWeight: 500,
                  py: 1,
                  px: 3,
                }}
              >
                Export as CSV
              </Button>
            </Box>

            {isOverview ? (
              <>
                <Box sx={{ width: "100%", mt: 3 }}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Tabs
                      sx={{
                        bgcolor: "background.default",
                        borderRadius: "24px 0px 0px 0px",
                      }}
                      value={tabValue}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                    >
                      <Tab
                        label="General Overview"
                        {...a11yProps(0)}
                        sx={{
                          "&.Mui-selected": { bgcolor: "#BC172F1a" },
                          my: 1,
                        }}
                      />
                      {userPermissions?.AccessAnalytics && (
                        <Tab
                          label="Analytics"
                          {...a11yProps(1)}
                          sx={{
                            "&.Mui-selected": { bgcolor: "#BC172F1a" },
                            my: 1,
                          }}
                        />
                      )}
                    </Tabs>
                    <Button
                      sx={{
                        bgcolor: "#333333",
                        px: 3,
                        fontSize: "12px",
                        color: "#fff",
                      }}
                      endIcon={<IoIosArrowDown />}
                      onClick={handleClick2}
                      aria-describedby={id2}
                    >
                      {dropdownLabel}
                    </Button>
                  </Box>
                  <CustomTabPanel value={tabValue} index={0}>
                    <GeneralOverview
                      data={chartData}
                      handleFilter={handleFilter}
                      handleUpdateDate={handleUpdateDate}
                      handleDetailsScreen={handleDetailsScreen}
                      values={dashboardChart?.ShowReservationDashboradOverview1}
                      tableData={tableData}
                      action={handleTableData}
                    />
                  </CustomTabPanel>
                  <CustomTabPanel value={tabValue} index={1}>
                    <SalesSummary
                      values={dashboardChart?.ShowReservationDashboradOverview1}
                      chartData={
                        dashboardChart?.ShowReservationDashboradOverview2
                      }
                      handleUpdateDayInterval={handleUpdateDayInterval}
                    />
                  </CustomTabPanel>
                </Box>
              </>
            ) : (
              <>
                <ReservationDetails
                  handleDetailsScreen={handleDetailsScreen}
                  data={detailsData}
                />
              </>
            )}
          </Box>
        </Box>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Calender
            future={false}
            past={false}
            onChildValue={(value) => setValue(value)}
          />
          <Box sx={{ p: 2 }} align="right">
            <Button onClick={handleFilter} variant="contained" sx={{ px: 4 }}>
              Filter
            </Button>
          </Box>
        </Popover>

        <Popover
          id={id2}
          open={open2}
          anchorEl={anchorEl2}
          onClose={handleClose2}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Box sx={{ p: 1 }}>
            <MenuItem
              sx={{ fontSize: "12px", py: 1 }}
              onClick={() => handleClickMenu(7, "Last_7_days")}
            >
              Last_7_days
            </MenuItem>
            <MenuItem
              sx={{ fontSize: "12px", py: 1 }}
              onClick={() => handleClickMenu(14, "Last_14_days")}
            >
              Last_14_days
            </MenuItem>
            <MenuItem
              sx={{ fontSize: "12px", py: 1 }}
              onClick={() => handleClickMenu(30, "Last_30_days")}
            >
              {" "}
              Last_30_days
            </MenuItem>
            <MenuItem
              sx={{ fontSize: "12px", py: 1 }}
              onClick={() => handleClickMenu(90, "Last_90_days")}
            >
              Last_90_days
            </MenuItem>
          </Box>
        </Popover>
      </ThemeContextProvider>
    </>
  );
};

export default Dashboard;
