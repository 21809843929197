import { Box } from "@mui/material";
import React, { useState } from "react";
import TopNav from "./TopNav";
import SideBar from "./SideBar";
import { Outlet } from "react-router-dom";
import { motion } from "framer-motion";

import { createTheme,  } from "@material-ui/core/styles";
import { ThemeContextProvider } from "../../theme/ThemeContextProvider";

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#BC172F",
    },
    background: {
      default: "#000",
    },
  },
  typography: {
    fontFamily: "outfit",
    button: {
      fontFamily: "outfit",
      textTransform: "initial",
    },
  },
});

const SharedLayout = () => {
  const NAV = {
    WIDTH: 70,
  };
  const [open, setOpen] = useState(false);
  const fadeInOutVariants = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  };
  return (
    <>
      <motion.div
        variants={fadeInOutVariants}
        initial="initial"
        animate="animate"
        exit="exit"
      >
    <ThemeContextProvider>
          {/* <CssBaseline /> */}
          <Box
            sx={{
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            <TopNav />
            <Box
              sx={{
                display: "flex",
                minHeight: 1,

                pt: { xl: 9, lg: 9, md: 9, sm:9, xs:9 },
              }}
            >
              <SideBar />

              <Box
                sx={{
                

                  height: "100vh",
                  width: {
                    lg: `calc(100% - ${NAV.WIDTH}px)`,
                    md: `calc(100% - ${NAV.WIDTH}px)`,
                    sm: "100%",
                    xs: "100%",
                  },
                  overflow: "scroll",
                  pb: 4,

                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                }}
              >
                <Outlet />
              </Box>
            </Box>
          </Box>
</ThemeContextProvider>
      </motion.div>
    </>
  );
};

export default SharedLayout;
