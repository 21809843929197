import { Avatar, Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { MdOutlineStarBorder } from "react-icons/md";
import { GoDotFill } from "react-icons/go";
import { TbBuildingStore } from "react-icons/tb";
import { LuLayoutDashboard } from "react-icons/lu";
import { Link } from "react-router-dom";
import { getAdvancedReportData } from "../../../api";
import Loader from "../../Common/Loader";
import Header from "../Header";

const AdvanceReport = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);

  const handleGetAdvancedReveiw = async () => {
    setIsLoading(true);

    await getAdvancedReportData()
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status) {
          setData(res?.data?.result);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    handleGetAdvancedReveiw();
  }, []);
  return (
    <>
      {isLoading && <Loader />}

      <Header title="          Advanced Reports" />
      <Box sx={{ mt: 7, px: 4 }}>
        <Grid container spacing={2}>
          <Grid item lg={6} md={4}>
            <Link to={`/dashboard/advanced-reports/rating-report`}>
              <Box
                sx={{
                  bgcolor: "#262626",
                  borderRadius: "8px",
                  height: "150px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  px: 4,
                  boxSizing: "border-box",
                  cursor: "pointer",
                  transition: "0.2s all linear",
                  "&:hover": {
                    bgcolor: "#333",
                    border: "1px solid #d4d4d4",
                  },
                }}
              >
                <Box
                  sx={{ display: "flex", columnGap: 1, alignItems: "center" }}
                >
                  <MdOutlineStarBorder style={{ color: "#fff" }} />
                  <Typography
                    sx={{
                      color: "#fff",
                      fontWeight: 500,
                      fontFamily: "optima",
                    }}
                  >
                    Ratings
                  </Typography>
                </Box>
                <Box
                  sx={{ display: "flex", columnGap: 1, alignItems: "center" }}
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      fontWeight: 500,
                      fontSize: "30px",
                      fontFamily: "optima",
                    }}
                  >
                    {isLoading || !data ? "0" : data?.Rating}
                  </Typography>
                  <GoDotFill style={{ color: "#BC172F" }} />
                </Box>
              </Box>
            </Link>
          </Grid>
          <Grid item lg={6} md={4}>
            <Link to={`/dashboard/advanced-reports/reservation-report`}>
              <Box
                sx={{
                  bgcolor: "#262626",
                  borderRadius: "8px",
                  height: "150px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  px: 4,
                  boxSizing: "border-box",
                  // cursor: "not-allowed",
                  transition: "0.2s all linear",
                  "&:hover": {
                    bgcolor: "#333",
                    border: "1px solid #d4d4d4",
                  },
                }}
              >
                <Box
                  sx={{ display: "flex", columnGap: 1, alignItems: "center" }}
                >
                  <TbBuildingStore style={{ color: "#fff" }} />
                  <Typography
                    sx={{
                      color: "#fff",
                      fontWeight: 500,
                      fontFamily: "optima",
                    }}
                  >
                    Reservations
                  </Typography>
                </Box>
                <Box
                  sx={{ display: "flex", columnGap: 1, alignItems: "center" }}
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      fontWeight: 500,
                      fontSize: "30px",
                      fontFamily: "optima",
                    }}
                  >
                    {isLoading || !data ? "0" : data?.TotalFinishReservations}
                  </Typography>
                  {/* <GoDotFill style={{color:'#BC172F'}}/> */}
                </Box>
              </Box>
            </Link>
          </Grid>
          <Grid item lg={6} md={4}>
            <Link to={`/dashboard/advanced-reports/events-reports`}>
              <Box
                sx={{
                  bgcolor: "#262626",
                  borderRadius: "8px",
                  height: "150px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  px: 4,
                  boxSizing: "border-box",
                  cursor: "pointer",
                  transition: "0.2s all linear",
                  "&:hover": {
                    bgcolor: "#333",
                    border: "1px solid #d4d4d4",
                  },
                }}
              >
                <Box
                  sx={{ display: "flex", columnGap: 1, alignItems: "center" }}
                >
                  <LuLayoutDashboard style={{ color: "#fff" }} />
                  <Typography
                    sx={{
                      color: "#fff",
                      fontWeight: 500,
                      fontFamily: "optima",
                    }}
                  >
                    Experiences
                  </Typography>
                </Box>
                <Box
                  sx={{ display: "flex", columnGap: 1, alignItems: "center" }}
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      fontWeight: 500,
                      fontSize: "30px",
                      fontFamily: "optima",
                    }}
                  >
                    {isLoading || !data ? "0" : data?.TotalTicketSold}
                  </Typography>
                  <GoDotFill style={{ color: "#BC172F" }} />
                </Box>
              </Box>
            </Link>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default AdvanceReport;
