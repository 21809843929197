import React, { useState } from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import { deleteGuest } from "../../../../api";
import { useSnackbar } from "notistack";
import anime from "../../../../assets/anime/KY0fkZoRyj.json";
import Lottie from "react-lottie";
import Loader from "../../../Common/Loader";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "backgroud.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 2,
  borderRadius: 3,
};

const DeleteGuest = ({ id, action }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleAlert = (variant, message) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant });
  };
  const handleDeleteGuest = async () => {
    setIsLoading(true);

    await deleteGuest(id)
      .then((res) => {
        setIsLoading(false);

        if (res?.data?.status) {
          handleAlert("success", `${res?.data?.success_message}`);
          handleClose();
          action();
        } else {
          handleAlert("error", `${res?.data?.error_message}`);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        handleAlert("error", `${err}`);
      });
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: anime,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <>
      {isLoading && <Loader />}
      <Button
        variant="contained"
        sx={{ py: 2, px: 5, borderRadius: "10px" }}
        onClick={handleOpen}
      >
        Delete Guest
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              bgcolor: "background.paper",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 2,
              borderRadius: "8px",
            }}
          >
            <Box sx={{ width: "30%" }}>
              <Lottie options={defaultOptions} width={100} height={100} />
            </Box>
            <Box sx={{ width: "70%" }}>
              <Typography sx={{ fontWeight: 600, fontSize: "16px" }}>
                {" "}
                Are you sure you want to delete this settings?
              </Typography>
              <Typography sx={{ fontSize: "12px", lineHeight: "20px" }}>
                This settings will be deleted immediately, you can’t undo this
                action.
              </Typography>

              <Box sx={{ mt: 2 }}>
                <Button
                  variant="contained"
                  onClick={handleClose}
                  sx={{ borderRadius: "8px", px: 4 }}
                >
                  Cancel
                </Button>
                <Button
                  variant="outlined"
                  sx={{ borderRadius: "8px", px: 4, ml: 2 }}
                  onClick={handleDeleteGuest}
                >
                  Delete
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default DeleteGuest;
