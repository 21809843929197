import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Typography,
  IconButton,
  Avatar,
  InputLabel,
  Button,
  Modal,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import { CloseOutlined } from "@mui/icons-material";
import DeleteModal from "../../Common/DeleteModal";
import { deleteFloorplan, editFloorPlan } from "../../../api";
import { useSnackbar } from "notistack";
import { ThemeContext } from "../../../theme/ThemeContextProvider";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "#1a1a1a",

  boxShadow: 24,
  p: 0,
  borderRadius: "8px",
};

const TableCard = ({ img, id, name, action, data }) => {
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleOpenEdit = (event) => {
    event.stopPropagation();
    setOpenEdit(true);
  };
  const handleOpenDelete = (event) => {
    event.stopPropagation();
    setOpenDelete(true);
  };

  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/dashboard/room/${id}`);
  };

  const [names, setNames] = useState("");
  const [location, setLocation] = useState("Ground floor");
  const [totalTables, setTotalTables] = useState("");

  const handleEditFloorplan = async () => {
    setIsLoading(true);
    await editFloorPlan(id, names, location, totalTables)
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status) {
          action();
          setOpenEdit(false);
          handleAlert("success", `${res?.data?.success_message}`);
        } else {
          handleAlert("error", `${res?.data?.error_message}`);
        }
      })
      .catch((err) => {
        setIsLoading(false);

        handleAlert("error", `${err}`);
      });
  };

  useEffect(() => {
    setTotalTables(data?.TableCount);
    setNames(name);
    setLocation(data?.Location);
  }, [data]);

  const handleSelect = (e, value) => {
    setLocation(e.target.value);
  };

  const { enqueueSnackbar } = useSnackbar();
  const handleAlert = (variant, message) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant });
  };

  const handleDeleteFloorPlan = async () => {
    setIsLoading(true);
    await deleteFloorplan(id)
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status) {
          action();
          setOpenDelete(false);
          handleAlert("success", `${res?.data?.success_message}`);
        } else {
          handleAlert("error", `${res?.data?.error_message}`);
        }
      })
      .catch((err) => {
        setIsLoading(false);

        handleAlert("error", `${err}`);
      });
  };

  const { isDarkTheme } = useContext(ThemeContext);
  return (
    <>
      <Box className="card" onClick={handleClick}>
        <Box
          sx={{
            height: "250px",
            display: "flex",
            flexDirection: "column",
            boxShadow:
              "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
            p: 1,
          }}
          className={isDarkTheme && "container-card bg-green-box"}
        >
          <Box
            src=""
            variant="square"
            sx={{
              width: "100%",
              height: "150px",
              backgroundImage: `url(${img})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              borderRadius: "5px",
            }}
          ></Box>
          <Box
            sx={{
              // display: "flex",
              // alignItems: "center",
              // justifyContent: "space-between",
              mt: 0,
            }}
          >
            <InputLabel
              sx={{
                fontSize: "16px",
                mt: 1,
                fontWeight: 700,

                cursor: "pointer",
              }}
            >
              {name}
            </InputLabel>
            <Typography sx={{ fontSize: "10px" }}>{data?.Location}</Typography>
            <Typography
              sx={{
                fontSize: "10px",
                textAlign: "right",
                lineHeight: "12px",
              }}
            >
              Table Count : {data?.TableCount}
            </Typography>
            <Box
              sx={{
                borderTop: "0.2px solid ",
                borderColor: "text.primary",
                mt: 2,
                display: "flex",
                justifyContent: "space-between",
                pt: 1,
              }}
            >
              <Button
                variant={!isDarkTheme && "contained"}
                onClick={handleOpenEdit}
                sx={{ fontWeight: 400, fontSize: "12px" }}
                // disabled
              >
                Edit
              </Button>
              <Button
                variant={!isDarkTheme && "contained"}
                onClick={handleOpenDelete}
                sx={{ color: "#ccc", fontWeight: 400, fontSize: "12px" }}
              >
                Delete
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>

      <Modal
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              bgcolor: "#333",
              p: 2,
              borderRadius: "8px 8px 0px 0px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ color: "#fff", fontWeight: 500 }}>
              Edit Floor Plan
            </Typography>
            <IconButton onClick={() => setOpenEdit(false)}>
              <CloseOutlined sx={{ color: "#fff" }} />
            </IconButton>
          </Box>
          <Box sx={{ p: 2 }}>
            <Box sx={{ pb: 4, borderBottom: "1px solid #fff" }}>
              <InputLabel
                sx={{ color: "#fff", fontWeight: 300, fontSize: "12px" }}
              >
                Name of Floor Plan
              </InputLabel>
              <TextField
                value={names}
                onChange={(e) => setNames(e.target.value)}
                placeholder="vip lounge"
                margin="dense"
                fullWidth
                InputProps={{
                  style: {
                    fontFamily: "outfit",
                    fontSize: "13px",
                    borderRadius: "10px",
                    border: "1px solid #fff",
                    color: "#fff",
                    offset: " 1px solid #fff",
                  },
                }}
              />

              <InputLabel
                sx={{ color: "#fff", mt: 3, fontWeight: 300, fontSize: "12px" }}
              >
                Location of Floor
              </InputLabel>
              <Select
                value={location}
                onChange={handleSelect}
                sx={{
                  width: "100%",
                  mt: 1,
                  fontSize: "12px",
                  border: "1px solid #fff",
                  color: "#fff",
                  borderRadius: "10px",
                }}
                displayEmpty
              >
                <MenuItem value="First floor" sx={{ fontSize: "12px" }}>
                  First floor
                </MenuItem>
                <MenuItem value="Second floor" sx={{ fontSize: "12px" }}>
                  Second floor
                </MenuItem>
                <MenuItem value="Ground floor" sx={{ fontSize: "12px" }}>
                  Ground floor
                </MenuItem>
              </Select>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography sx={{ color: "#fff", fontWeight: 400 }}>
                Add Tables
              </Typography>
              <InputLabel
                sx={{ color: "#fff", mt: 1, fontWeight: 300, fontSize: "12px" }}
              >
                How many tables are in the lounge
              </InputLabel>
              <TextField
                value={totalTables}
                onChange={(e) => setTotalTables(e.target.value)}
                type="number"
                margin="dense"
                fullWidth
                InputProps={{
                  style: {
                    fontFamily: "outfit",
                    fontSize: "13px",
                    borderRadius: "10px",
                    border: "1px solid #fff",
                    color: "#fff",
                    offset: " 1px solid #fff",
                  },
                }}
              />
            </Box>

            <Button
              fullWidth
              sx={{ py: 2, mt: 3, borderRadius: "10px" }}
              variant="contained"
              onClick={handleEditFloorplan}
            >
              Save changes to {name}
            </Button>
          </Box>
        </Box>
      </Modal>

      <DeleteModal
        open={openDelete}
        handleClose={() => setOpenDelete(false)}
        handleDelete={handleDeleteFloorPlan}
        isLoading={isLoading}
      />
    </>
  );
};

export default TableCard;
