import React, {useState} from 'react';
import { Box, Button, Modal, Typography } from '@mui/material';

import anime from "../../assets/anime/KY0fkZoRyj.json";
import Lottie from "react-lottie";
import Loader from './Loader';



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: '#1a1a1a',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 2,
  borderRadius:3
};

const DeleteModal = ({ isLoading,open, handleClose, handleDelete}) => {


  
    const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: anime,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
<>
{
  isLoading && <Loader/>
}
     

                  <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <Box
            sx={{
              bgcolor: "#1a1a1a",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 2,
            }}
          >
            <Box sx={{ width: "30%" }}>
              <Lottie options={defaultOptions} width={100} height={100} />
            </Box>
            <Box sx={{ width: "70%" }}>
              <Typography
                sx={{ color: "#fff", fontWeight: 600, fontSize: "16px" }}
              >
                {" "}
                Are you sure you want to delete this settings?
              </Typography>
              <Typography
                sx={{ color: "#ccc", fontSize: "12px", lineHeight: "20px" }}
              >
                This settings will be deleted immediately, you can’t undo this
                action.
              </Typography>

              <Box sx={{ mt: 2 }}>
                <Button
                  variant="contained"
                  onClick={handleClose}
                  sx={{ borderRadius: "8px", px: 4 }}
                >
                  Cancel
                </Button>
                <Button
                  variant="outlined"
                  sx={{ borderRadius: "8px", px: 4, ml: 2 }}
                onClick={handleDelete}
                >
                  Delete
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
</>
  )
}

export default DeleteModal