import {
  Button,
  Box,
  Typography,
  Grid,
  IconButton,
  Avatar,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";

import { editBasicInfoLogo } from "../../../../../api";
import Loader from "../../../../Common/Loader";
import galleryIcon from "../../../../../assets/Icons/gallery.svg";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: 600, md: 600, sm: 400, xs: 350 },
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  height: "70vh",
};
const Section2 = ({ data, action }) => {
  const fileInputRef = useRef(null);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(" ");

  const handleButtonClick = () => {
    fileInputRef.current.click(); // Trigger file input click
  };
  const [selectedFileURL, setSelectedFileURL] = useState(null);
  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const dataURL = e.target.result;
        setSelectedFileURL(dataURL);
      };
      reader.readAsDataURL(file);
    }
  };

  // useEffect(() => {
  //   setDescription(data?.Description);
  // }, [data]);

  const handleEdit = async () => {
    setIsLoading(true);
    await editBasicInfoLogo(selectedFile)
      .then((res) => {
        setIsLoading(false);
        setOpen(false);
        action();
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  return (
    <>
      <Button variant="outlined" onClick={handleOpen} sx={{ width: "100px" }}>
        Edit
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {isLoading && <Loader />}
          <Box
            sx={{
              height: "60px",
              bgcolor: "background.card",
              borderBottom: "1px solid #BC172F",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              px: 2,
              borderRadius: "12px 12px 0px 0px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "optima",

                textAlign: "center",
                fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
                fontWeight: 700,
              }}
            >
              Edit Logo
            </Typography>

            <IconButton onClick={handleClose}>
              <CloseIcon sx={{}} />
            </IconButton>
          </Box>
          <Box
            sx={{
              bgcolor: "background.paper",
              px: { lg: 5, md: 5, sm: 2, xs: 2 },
              height: "65vh",
              overflow: "scroll",
              borderRadius: "0px 0px 12px 12px",
              py: 2,
            }}
          >
            <Box
              sx={{
                bgcolor: {
                  lg: "background.card",
                  md: "background.card",
                  sm: "none",
                  xs: "none",
                },
                mt: 2,
                borderRadius: "5px",
              }}
            >
              <Box
                sx={{
                  margin: "0 auto",
                  width: { lg: "85%", md: "85%", sm: "90%", xs: "100%" },
                  py: 2,
                }}
              >
                <Typography variant="caption" sx={{}}>
                  Add a brightly illuminated image of your restaurant's logo.
                </Typography>

                <Typography
                  sx={{
                    fontFamily: "outfit",
                    fontSize: {
                      lg: "12px",
                      md: "12px",
                      sm: "10px",
                      xs: "10px",
                    },
                    fontWeight: 500,
                    mt: 1,
                    lineHeight: "20px",
                  }}
                >
                  By including a well-lit logo image, you can instantly enhance
                  your restaurant's branding and make it more easily
                  recognizable to customers
                </Typography>
                <Box
                  sx={{
                    mt: 3,
                    border: "1px dashed #ccc",
                    borderRadius: "5px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    py: 8,
                  }}
                >
                  {selectedFileURL ? (
                    <Box>
                      <Avatar
                        variant="square"
                        src={selectedFileURL}
                        alt="Selected File"
                        onClick={handleButtonClick}
                      />
                    </Box>
                  ) : (
                    <img
                      src={galleryIcon}
                      alt="gallery_icon"
                      onClick={handleButtonClick}
                    />
                  )}

                  <Typography
                    sx={{
                      mt: 2,
                      fontFamily: "outfit",
                      fontSize: "10px",
                    }}
                  >
                    Drag or drop your photo here
                  </Typography>
                  <Button
                    onClick={handleButtonClick}
                    variant="contained"
                    sx={{
                      mt: 2,
                      background: "#ccc",
                      color: "#2b2b2b",
                      "&:hover": { background: "#fff" },
                    }}
                  >
                    Browse Files
                  </Button>
                  <input
                    type="file"
                    ref={fileInputRef}
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={handleFileSelect}
                  />
                </Box>
                <Box
                  sx={{
                    mt: 3,
                    bgcolor: "#ffffff1a",
                    borderRadius: "5px",
                    p: 3,
                    width: "100%",
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontSize: {
                          lg: "14px",
                          md: "14px",
                          sm: "12px",
                          xs: "12px",
                        },
                        fontWeight: 500,

                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <TipsAndUpdatesIcon sx={{ mr: 1 }} /> Tips for adding
                      logos
                    </Typography>
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <Typography
                      sx={{
                        fontSize: {
                          lg: "12px",
                          md: "12px",
                          sm: "12px",
                          xs: "12px",
                        },
                        fontWeight: 300,

                        display: "flex",
                        fontFamily: "outfit",
                        alignItems: "center",
                      }}
                    >
                      <CheckCircleIcon sx={{ mr: 1, fontSize: "18px" }} /> Do
                      not use photos with people
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: {
                          lg: "12px",
                          md: "12px",
                          sm: "12px",
                          xs: "12px",
                        },
                        fontWeight: 300,

                        display: "flex",
                        fontFamily: "outfit",
                        alignItems: "center",
                      }}
                    >
                      <CheckCircleIcon sx={{ mr: 1, fontSize: "18px" }} /> Use
                      only jpeg or png formats
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: {
                          lg: "12px",
                          md: "12px",
                          sm: "12px",
                          xs: "12px",
                        },
                        fontWeight: 300,

                        display: "flex",
                        fontFamily: "outfit",
                        alignItems: "center",
                      }}
                    >
                      <CheckCircleIcon sx={{ mr: 1, fontSize: "18px" }} />
                      The bigger image size should be “1280 * 720” pixels
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: {
                          lg: "12px",
                          md: "12px",
                          sm: "12px",
                          xs: "12px",
                        },
                        fontWeight: 300,

                        display: "flex",
                        fontFamily: "outfit",
                        alignItems: "center",
                      }}
                    >
                      <CheckCircleIcon sx={{ mr: 1, fontSize: "18px" }} />
                      Ensure the picture sizes are 10mb max
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box sx={{ mt: 2, mb: 3 }} align="right">
              <Button variant="contained" onClick={handleEdit}>
                Save Changes
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Section2;
