import {
  Box,
  Button,
  Checkbox,
  Drawer,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItemButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { motion } from "framer-motion";
import React, { useState, useEffect } from "react";
import { TbFilterSearch } from "react-icons/tb";
import { BiExport } from "react-icons/bi";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { TbFileTypePdf } from "react-icons/tb";

import { RiSearch2Line } from "react-icons/ri";
import SendEmail from "./SendEmail";
import CsvExport from "../../../Common/CsvExport";

const CRM = ({ data }) => {
  const [filterDrawer, setFilterDrawer] = React.useState(false);
  const restaurantName = localStorage.getItem("restaurantName");
  const toggleDrawer = (newOpen) => () => {
    setFilterDrawer(newOpen);
  };

  const [filters, setFilters] = useState([
    { title: "Name", filter: true },
    { title: "Email", filter: true },
    { title: "Phone No", filter: true },
    // { title: "Guest Tag", filter: true },
    { title: "Upcoming", filter: true },
    // { title: "Avg Spend", filter: true },
    // { title: "Last Visit", filter: false },
    { title: "Finished Reservation", filter: false },
    { title: "Cancelled Reservation", filter: false },
  ]);

  const toggleFilter = (title) => {
    setFilters((prevFilters) =>
      prevFilters.map((filter) =>
        filter.title === title ? { ...filter, filter: !filter.filter } : filter
      )
    );
  };

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleCheckboxChange = (id, name, email) => {
    setSelectedRows((prevSelectedRows) => {
      const isSelected = prevSelectedRows.some((row) => row.id === id);
      if (isSelected) {
        return prevSelectedRows.filter((row) => row.id !== id);
      } else {
        return [...prevSelectedRows, { id, name, email }];
      }
    });
  };

  const handleSelectAllChange = (event) => {
    setSelectAll(event.target.checked);
    if (event.target.checked) {
      const allRows = data?.map((row) => ({
        id: row.Id,
        name: row.FirstName,
        email: row.EmailAddress,
      }));
      setSelectedRows(allRows);
    } else {
      setSelectedRows([]);
    }
  };

  const [searchParams, setSearchParams] = useState("");

  const [filteredData, setFilteredData] = useState(data);

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();

    // Filter items based on the search term
    const filteredItems = data?.filter(
      (item) =>
        item?.FirstName.toLowerCase().includes(searchTerm) ||
        item?.LastName.toLowerCase().includes(searchTerm) ||
        item?.EmailAddress.toLowerCase().includes(searchTerm)
    );

    setFilteredData(filteredItems);
    setSearchParams(searchTerm);
  };

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const generatePDF = () => {
    const doc = new jsPDF();

    doc.setFont("helvetica", "bold");
    doc.setFontSize(16);

    // Add heading
    doc.text(
      ` ${restaurantName} Guest List - ${formatDate(new Date())}`,
      10,
      10
    );
    // Set custom font and size for the table
    doc.setFont("helvetica");
    doc.setFontSize(12);

    const tableColumn = [
      "Name",
      "Email",
      "Phone Number",
      "Upcoming",
      "Finished",
      "Cancelled",
    ];
    const tableRows = [];

    filteredData.forEach((item) => {
      const itemData = [
        `${item.FirstName} ${item?.LastName}`,
        `${item.EmailAddress}`,
        item.PhoneNumber,
        item.UpcomingBookings ? item.UpcomingBookings : 0,
        item?.BookingAttendance,
        item.CancelledBookings,
      ];
      tableRows.push(itemData);
    });

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 15,
      styles: {
        fontSize: 8,
        font: "helvetica",
      },
      headStyles: {
        fillColor: [188, 23, 47],
      },
      didDrawPage: function (data) {
        // Footer
        const str = "Powered by Reisty";
        doc.setFontSize(8);
        doc.text(
          str,
          data.settings.margin.left,
          doc.internal.pageSize.height - 10
        );
      },
    });

    doc.save(`${restaurantName}_gl(${new Date()}).pdf`);
  };

  return (
    <>
      <Box
        sx={{
          bgcolor: "background.paper",
          borderBottom: "0.5px solid #fff",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          boxShadow:
            "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
          px: 4,
        }}
      >
        <Box
          component={motion.div}
          animate={{
            scale: 1,
            x: 0,
          }}
          initial={{
            scale: 0.1,
          }}
          transition={{
            duration: 0.3,
            ease: "backInOut",
          }}
          sx={{ display: "flex", alignItems: "center", columnGap: 3, my: 2 }}
        >
          <IconButton
            onClick={toggleDrawer(true)}
            whileHover={{ scale: 1.5 }}
            whileTap={{ scale: 0.9, rotate: "2.5deg" }}
            transition={{
              duration: 0.2,
              ease: "easeInOut",
            }}
            component={motion.button}
          >
            <TbFilterSearch style={{ fontSize: "16px" }} />
          </IconButton>
          <TextField
            value={searchParams}
            onChange={handleSearch}
            size="small"
            sx={{ width: "250px" }}
            placeholder="Search contact, email or phone no"
            InputProps={{
              style: {
                background: "#fff",
                fontSize: "10px",
                color: "#151515",
              },
            }}
          />
        </Box>
        <Box
          component={motion.div}
          animate={{
            scale: 1,
            x: 0,
          }}
          initial={{
            scale: 0.1,
          }}
          transition={{
            duration: 0.3,
            ease: "backInOut",
          }}
          sx={{ display: "flex", columnGap: 2, alignItems: "center" }}
        >
          <SendEmail selectedGuest={selectedRows} />

          <CsvExport
            data={filteredData}
            name={`${restaurantName} Guest List - ${formatDate(new Date())}`}
          />
          <Button
            variant="contained"
            startIcon={<TbFileTypePdf />}
            onClick={generatePDF}
          >
            Export as PDF
          </Button>
        </Box>
      </Box>
      <Box sx={{ px: 4 }}>
        <TableContainer>
          <Table>
            <TableHead
              sx={{
                bgcolor: "background.card",
                boxShadow:
                  "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
              }}
            >
              <TableRow>
                <TableCell sx={{ border: "none" }}>
                  <Checkbox
                    checked={selectAll}
                    onChange={handleSelectAllChange}
                    sx={{}}
                  />
                </TableCell>
                {filters.map((item, index) => (
                  <TableCell
                    key={index}
                    sx={{
                      border: "none",

                      fontFamily: "outfit",
                      fontSize: "12px",
                      display: item?.filter === false && "none",
                    }}
                  >
                    {item?.title}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData?.map((item, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ border: "none" }}>
                    <Checkbox
                      sx={{}}
                      checked={selectedRows.some(
                        (selectedRow) => selectedRow.id === item.Id
                      )}
                      onChange={() =>
                        handleCheckboxChange(
                          item?.Id,
                          item.FirstName,
                          item?.EmailAddress
                        )
                      }
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "none",

                      fontFamily: "outfit",
                      fontSize: "12px",
                      wordSpacing: 2,
                      ...(filters[0]?.filter === false && {
                        display: "none",
                      }),
                    }}
                  >{`${item?.FirstName}  ${item?.LastName}`}</TableCell>
                  <TableCell
                    sx={{
                      border: "none",

                      fontFamily: "outfit",
                      fontSize: "12px",
                      wordSpacing: 2,
                      ...(filters[1]?.filter === false && {
                        display: "none",
                      }),
                    }}
                  >{`${item?.EmailAddress}  `}</TableCell>
                  <TableCell
                    sx={{
                      border: "none",

                      fontFamily: "outfit",
                      fontSize: "12px",
                      wordSpacing: 2,
                      ...(filters[2]?.filter === false && {
                        display: "none",
                      }),
                    }}
                  >{`${item?.PhoneNumber}  `}</TableCell>

                  <TableCell
                    sx={{
                      border: "none",

                      fontFamily: "outfit",
                      fontSize: "12px",
                      wordSpacing: 2,
                      ...(filters[3]?.filter === false && {
                        display: "none",
                      }),
                    }}
                  >
                    {item?.UpcomingBookings || 0}
                  </TableCell>

                  <TableCell
                    sx={{
                      border: "none",

                      fontFamily: "outfit",
                      fontSize: "12px",
                      wordSpacing: 2,
                      ...(filters[4]?.filter === false && {
                        display: "none",
                      }),
                    }}
                  >
                    {item?.guestReservationHitory
                      ? item?.guestReservationHitory?.length
                      : 0}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "none",

                      fontFamily: "outfit",
                      fontSize: "12px",
                      wordSpacing: 2,
                      ...(filters[5]?.filter === false && {
                        display: "none",
                      }),
                    }}
                  >
                    {item?.CancelledBookings || 0}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Drawer open={filterDrawer} onClose={toggleDrawer(false)}>
        <Box sx={{ width: "30vw" }}>
          <Box
            sx={{ width: "100%", background: "#1a1a1a", height: "100vh", p: 3 }}
          >
            <Box sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
              <TbFilterSearch style={{ color: "#fff", fontSize: "20px" }} />
              <Typography
                sx={{ color: "#fff", fontWeight: 600, fontSize: "16px" }}
              >
                Filter
              </Typography>
            </Box>
            <Box sx={{ mt: 4 }}>
              <TextField
                value={searchParams}
                onChange={handleSearch}
                fullWidth
                placeholder="Search contact, email or phone no"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <RiSearch2Line />
                    </InputAdornment>
                  ),
                  style: {
                    background: "#fff",
                    fontSize: "12px",
                    color: "#151515",
                    borderRadius: "8px",
                  },
                }}
              />
            </Box>

            {/* <Box sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6}>
                  <Box>
                    <InputLabel sx={{ color: "#b3b3b3", fontWeight: 400 }}>
                      FROM
                    </InputLabel>
                    <TextField
                      fullWidth
                      margin="dense"
                      type="date"
                      InputProps={{
                        style: {
                          background: "#fff",
                          fontSize: "12px",
                          borderRadius: "8px",
                        },
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item lg={6} md={6}>
                  <Box>
                    <InputLabel sx={{ color: "#b3b3b3", fontWeight: 400 }}>
                      TO
                    </InputLabel>
                    <TextField
                      fullWidth
                      margin="dense"
                      type="date"
                      InputProps={{
                        style: {
                          background: "#fff",
                          fontSize: "12px",
                          borderRadius: "8px",
                        },
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box> */}
            <Box sx={{ mt: 4 }}>
              <Typography sx={{ color: "#b3b3b3" }}>SORT BY</Typography>
              <List sx={{ mt: 2 }}>
                {filters?.map((item, index) => (
                  <ListItemButton
                    key={index}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      border: "0.5px solid #b3b3b3",
                      mb: 1,
                    }}
                    onClick={() => toggleFilter(item.title)}
                  >
                    <Typography sx={{ color: "#fff" }}>
                      {item?.title}
                    </Typography>
                    <Checkbox sx={{ color: "#fff" }} checked={item?.filter} />
                  </ListItemButton>
                ))}
              </List>
            </Box>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default CRM;

const formatDate = (dateString) => {
  const date = new Date(dateString);

  const day = date.getUTCDate();
  const month = date.toLocaleString("en-GB", { month: "short" });
  const year = date.getUTCFullYear();

  return `${day} ${month} ${year}`;
};
