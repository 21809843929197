import { Navigate } from "react-router-dom";
import { PATHS } from "./route.path";
// import { useAuthContext } from '../hooks/useAuthContext';

const ProtectedRoute = ({ children }) => {
  const user = JSON.parse(window.localStorage.getItem("userInfo"));


  if (user === null || user === undefined) {
    return <Navigate to={PATHS.LOGIN} />;
  }

  return children;
};

export default ProtectedRoute;
