import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  TextField,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import calender from "../../../../assets/Icons/calendar.svg";
import profileIcon from "../../../../assets/Icons/user-square.svg";
import clock from "../../../../assets/Icons/clock.svg";
import SearchIcon from "@mui/icons-material/Search";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import { deleteGuest, getGuestAnalysis } from "../../../../api";
import Loader from "../../../Common/Loader";
import { useSnackbar } from "notistack";

import AdvancedGuestOutlet from "./AdvancedGuestOutlet";

const GuestList = ({ data, action }) => {
  const [selected, setSelected] = useState(null);
  const [outletData, setOutletData] = useState(null);
  const [alignment, setAlignment] = React.useState("left");
  const [selectedButton, setSelectedButton] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [datas, setData] = useState(null);

  const { enqueueSnackbar } = useSnackbar();
  const handleAlert = (variant, message) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant });
  };

  const handleSelect = (index, data) => {
    setSelected(index);
    setOutletData(data);
  };

  const handleGetAnalysis = async () => {
    setIsLoading(true);
    await getGuestAnalysis(outletData?.Id)
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status) {
          setData(res?.data?.result[0]);
        }
      })
      .catch((err) => {
        setIsLoading(false);

        handleAlert("error", `${err?.message}`);
      });
  };
  useEffect(() => {
    handleGetAnalysis();
  }, [outletData]);

  const [searchParams, setSearchParams] = useState("");

  const [filteredData, setFilteredData] = useState(data);

  const handleSearch = (e) => {
    console.log(data);
    const searchTerm = e.target.value.toLowerCase();
    // Filter items based on the search term
    const filteredItems = data?.filter(
      (item) =>
        item?.FirstName?.toLowerCase()?.includes(searchTerm) ||
        "" ||
        item?.LastName?.toLowerCase()?.includes(searchTerm) ||
        "" ||
        item?.EmailAddress?.toLowerCase()?.includes(searchTerm) ||
        ""
    );

    console.log(searchTerm, e, data);

    setFilteredData(filteredItems);
    setSearchParams(searchTerm);
  };

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  return (
    <>
      {isLoading && <Loader />}
      <Grid container sx={{ display: "flex" }}>
        <Grid item lg={3} md={4} sx={{}}>
          <Box
            className="hide_scrollbar"
            sx={{
              borderRight: "0.5px solid ",
              height: "85vh",
              overflow: "scroll",
              borderColor: "text.primary",
            }}
          >
            <Box
              sx={{
                p: 2,
                bgcolor: "background.default",
                borderBottom: "0.5px solid #fff",
                width: "100%",
              }}
            >
              <TextField
                value={searchParams}
                onChange={handleSearch}
                size="small"
                fullWidth
                placeholder="Search contact, email or phone no"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{}} />
                    </InputAdornment>
                  ),
                  style: {
                    fontFamily: "outfit",
                    fontSize: "13px",
                    borderRadius: "10px",

                    fontWeight: 500,

                    background: "background.paper",

                    // Replace with your desired font family
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                bgcolor: "background.card",
                p: 1,
                px: 2,
                boxShadow:
                  "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
              }}
            >
              <Typography sx={{ fontSize: "12px", fontWeight: 500 }}>
                All available guests
              </Typography>
            </Box>
            <Box>
              <List sx={{ mt: 1 }}>
                {data &&
                  filteredData?.map((item, index) => (
                    <ListItemButton
                      selected={index === selected}
                      key={index}
                      onClick={() => handleSelect(index, item)}
                      sx={{
                        "&.Mui-selected": { background: "background.card" },
                        cursor: "pointer",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          // justifyContent: "space-between",
                          columnGap: 3,
                        }}
                      >
                        <Avatar
                          sx={{
                            width: "40px",
                            height: "40px",
                            bgcolor: "#d9d9d9",
                            color: "#000",
                          }}
                        >{`${item.FirstName?.charAt(0)}${item.LastName?.charAt(
                          0
                        )}`}</Avatar>
                        <Box>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: 500,
                            }}
                          >
                            {`${item?.FirstName} ${item?.LastName}`}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "10px",
                              fontWeight: 400,
                              mt: -0.5,
                            }}
                          >
                            {item?.EmailAddress}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "10px",
                              fontWeight: 300,
                              mt: -0.4,
                            }}
                          >
                            {item?.PhoneNumber}
                          </Typography>
                        </Box>
                      </Box>
                    </ListItemButton>
                  ))}
              </List>
            </Box>
          </Box>
        </Grid>
        <Grid item lg={9} md={8} sx={{ height: "85vh", overflow: "scroll" }}>
          {!outletData || isLoading ? (
            <>
              <Box
                sx={{ display: "grid", placeItems: "center", height: "100%" }}
              >
                <Typography sx={{ fontWeight: 500 }}>
                  No guest selected{" "}
                </Typography>
              </Box>
            </>
          ) : (
            <>
              <AdvancedGuestOutlet datas={datas} action={handleGetAnalysis} />
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default GuestList;

function formatDate(dateString) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = months[date.getMonth()];
  const day = date.getDate();
  const dayOfWeek = days[date.getDay()];

  return `${dayOfWeek}, ${month} ${day}, ${year}`;
}
