import { Avatar, Box, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

const SectionOne = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

   const videoUrl = `https://www.youtube.com/embed/5rzCN7C47BI`;
  return (
    <>
      <Box sx={{ bgcolor: "#FDEDEF" }} id="learn_more">
        <Box
          sx={{
            margin: "0 auto",
            width: { lg: "85%", md: "85%", sm: "90%", xs: "90%" },
            py: 8,
          }}
        >
          <Grid container spacing={4}>
            <Grid item lg={6} md={6} xs={12} sm={12}>
              <Box sx={{ width: "100%", height:{lg:'350px', md:'350px', sm:'300px', xs:'250px'} }}>
                {/* <Avatar
                  src={video}
                  variant="square"
                  sx={{ height: "100%", width: "100%" }}
                /> */}
                  <iframe
        src={videoUrl}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
 
        title="Embedded YouTube Video"
        style={{ width: '100%', height: '100%' }}
      />
              </Box>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Box data-aos="fade-up" sx={{ pr: { lg: 5, xs: 0 } }}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    mb: 2,
                    fontSize: {
                      lg: "24px",
                      md: "24px",
                      sm: "20px",
                      xs: "20px",
                    },
                    color: "#BC172F",
                    textTransform: "capitalize",
                  }}
                >
                  What makes Reisty the ideal choice for restaurants?
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    fontSize: {
                      lg: "14px",
                      md: "14px",
                      sm: "12px",
                      xs: "12px",
                    },
                    color: "#2B2B2B",
                    fontFamily: "outfit",
                  }}
                >
                  With Innovative Features, and an intuitive Interface. Reisty
                  empowers restaurants looking to maximize efficiency and
                  customer satisfaction.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default SectionOne;
